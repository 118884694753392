//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary:       									#03738B; // Bootstrap variable
$secondary:       									#049BBD; // Bootstrap variable
$primary-hover:    									#137085; // Custom variable
$secondary-hover:    								#248ba2; // Custom variable
$primary-light:    									#E1F0FF; // Custom variable
$secondary-light:    									#E1F0FF; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable
$secondary-inverse:  									#FFFFFF; // Custom variable
$font-family-base:            						'Roboto Condensed', sans-serif;

$info:         		 								rgba(#109BBD, 0.4); // Bootstrap variable
$info-hover:   		 								rgba(#109BBD, 0.7); // Custom variable
$info-light:   		 								#0A738B; // Custom variable
$info-inverse: 		 								#0A738B; // Custom variable


// Success colors
$success:       									#139345 !default; // Bootstrap variable
$success-hover:    									#139345 !default; // Custom variable
$success-light:    									#C9F7F5 !default; // Custom variable
$success-inverse:  									white !default; // Custom variable

// Danger colors
$danger:        									#EB3815 !default; // Bootstrap variable
$danger-hover:     									#EE2D41 !default; // Custom variable
$danger-light:     									#FFE2E5 !default; // Custom variable
$danger-inverse:   									white !default; // Custom variable 