$orange: #F15E3D;

.counter_container {
    background: url(../assets/summaryBg.jpg);
    width: 100%;
    background-size: cover;
    padding: 20px;
    background-position: 100%;
    text-align: center;
    color: white;
    border-radius: 2px;
}

.acSummaryItem {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;

    &:after {
        content: "";
        height: 80%;
        width: 2px;
        background-color: white;
        top: 10%;
        bottom: 10%;
        position: absolute;
        right: 0;
    }

    &:last-child:after {
        display: none;
    }

    .acSummaryItem__title {
      min-height: 3em;
        font-weight: 500;
        font-size: 14px;
    }
    
    .acSummaryItem__number {
        font-weight: bold;
        font-size: 14px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content:center;
    }
}

.summary__container {
    background-color: #FFFBFA;
    border: 1px solid $orange;
    border-radius: 2px;
    padding: 17px 16px;
}

.summary_title {
    font-size: 21px;
    font-weight: bold;
    color: $orange;
}
