// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~toastr/toastr.scss";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.wizard_item {
  position: relative;
  flex: 1;
  text-align: center;
  color: #7E8299;
  
  &.active {
    color: $secondary !important;
  }

  .wizard_icon {
    min-height: 65px;
  }

  .arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) !important;
  }
  .wizard_label {
    margin-top: 19px;
    font-size: 16px;
    display: block;
  }
}

.section_title {
  font-weight: bold;
  font-size: 18px;
  margin-top: 77px;
  text-align: center;
  color: $primary;
  margin-bottom: 45px;
}

.bold {
  font-weight: bold;
}

.text-secondary {
  color: $secondary !important;
}

.wrapper_editor {
  background: #F3F6F9;
}

.editorClassName {
  min-height: 200px;
}

.f-14 {
  font-size: 14px;
}

.f-22 {
  font-size: 22px;
}

.bg-orange {
  background: #EB3815;
}

.circular {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.card-title {
  font-weight: bold;
  color: $primary;
  font-size: 20px;
}

.is-invalid-select {
  border: 1px solid red;
}

.rdw-editor-main {
  background: #f4f4f4;
  min-height: 200px;
}

.btn i {
  padding-right: 0 !important;
}

.wrapper_EditorField.is-invalid {
  border: 1px solid red;
}