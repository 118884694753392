//
// Aside dark theme
//


// Initialization of global variables, mixins and functions
@import "../../../init";

.aside {
	background: url("../../../../media/bg/aside.jpg");
	background-size: cover;
	background-position: 100%;

	// Aside Menu
	.aside-menu {
		// Scrollbar
		@include perfect-scrollbar-theme(transparent);
	}
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, dark);
